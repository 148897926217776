import { useState, useEffect } from 'react'
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Anchor, Text, Table, TableBody, TableHeader, TableRow, TableCell } from 'grommet'

import firebase from 'firebase/compat/app'

const SankirtanBoard = ({ user, firestore }) => {
  const [dashData, setDashData] = useState()

  useEffect(() => {
    let isCancelled = false

    const fetchDashboard = async () => {
      const scoresQuery = firestore.collection('scores').orderBy('score', 'desc').limit(20)
      const scoresData = await scoresQuery.get()
      const fusers = scoresData.docs.reduce((f, snap) => {
        f[snap.id] = snap.data().score
        return f
      }, {})

      if (Object.keys(fusers).length < 1) {
        return
      }

      if (isCancelled) {
        return
      }

      const profileQuery = firestore.collection('profiles').where(firebase.firestore.FieldPath.documentId(), 'in', Object.keys(fusers))
      const profileData = await profileQuery.get()

      const dData = profileData.docs.map(snap => {
        const data = snap.data()
        return {
          uid: snap.id,
          profileName: data.profileName,
          mentor: data.mentor,
          score: fusers[snap.id]
        }
      })

      if (isCancelled) {
        return
      }

      if (!isCancelled) {
        setDashData(dData)
      }
    }

    fetchDashboard()

    return () => {
      isCancelled = true
    }

  }, [])

  return (<>
    <Card background='light-1' width='large' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold' size='large'>Sankirtana Board</Text>
      </CardHeader>
      <CardBody pad='medium'>
        {(!dashData) && <Spinner />}
        {dashData && (dashData.length === 0) && <Text>No data</Text>}
        {dashData && (dashData.length > 0) && <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope='col' border='bottom'>Name</TableCell>
              <TableCell scope='col' border='bottom' align='center'>Mentor</TableCell>
              <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dashData.map((data, index) => {
              return(
                <TableRow key={index}>
                  <TableCell style={{ paddingTop: '1em' }}>
                    <Anchor href={`/profile/${data.uid}`} label={ (user && user.uid === data.uid) ? 'You' : data.profileName } />
                  </TableCell>
                  <TableCell style={{ paddingTop: '1em' }} align='center'>{data.mentor}</TableCell>
                  <TableCell style={{ paddingTop: '1em' }} align='right'>{data.score}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>}
      </CardBody>

      <CardFooter pad='medium' background='light-2'>
        <Box flex direction='row' align='center' gap='medium' justify='between'>
          <Text></Text>
          <Button size='small' href='/sankirtana' label='Add your score' />
        </Box>
      </CardFooter>
    </Card>
  </>)

}


export default SankirtanBoard