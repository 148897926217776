import React, { useEffect, useState } from 'react'
import { Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell, Button } from 'grommet'
import { useParams } from 'react-router-dom'
import ProfileGraph from './ProfileGraph'

const Profile = ({ firestore }) => {
  const { uid } = useParams()

  const [dashData, setDashData] = useState()
  const [profileName, setProfileName] = useState('')
  const [score, setScore] = useState()

  useEffect(() => {
    let isCancelled = false

    const fetchProfileData = async () => {
      if (isCancelled) {
        return
      }

      const sankirtanData = await firestore.collection('sankirtana').where('uid', '==', uid).get()
      const dData = sankirtanData.docs.map(snap => snap.data())
      
      if (isCancelled) {
        return
      }

      setDashData(dData)
      setScore(dData.reduce((x, y) => {
        return x + parseInt(y.score)
      }, 0))

      const profileRef = await firestore.collection('profiles').doc(uid)
      profileRef.get().then((profile) => {
        if (!isCancelled) {
          const profileData = profile.data()
          setProfileName(profileData.profileName)
        }
      })
    }

    fetchProfileData()

    return () => {
      isCancelled = true
    }

  }, [])
  
  return (<>
    <Card background='light-1' width='large' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Box flex justify='between' direction='row' align='center'>
          <Text weight='bold' size='large'>{profileName}</Text>
          <Text weight='bold'>Score: {score}</Text>
        </Box>
      </CardHeader>
      <CardBody pad='medium'>
        {!dashData && <Spinner />}
        {dashData && (dashData.length === 0) && <Text>No data</Text>}
        {dashData && (dashData.length > 0) && <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope='col' border='bottom'>Date</TableCell>
              <TableCell scope='col' border='bottom'>Book Name</TableCell>
              <TableCell scope='col' border='bottom' align='center'>Count</TableCell>
              <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dashData && dashData.map((data, index) => {
              return(
                <TableRow key={index}>
                  <TableCell>
                    {data.createdAt ? String(data.createdAt.toDate().toLocaleDateString()) : '-'}
                  </TableCell>
                  <TableCell>{data.bookName}</TableCell>
                  <TableCell align='center'>{data.numBook}</TableCell>
                  <TableCell align='right'>{data.score}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>}
      </CardBody>

      <CardFooter pad='medium' background='light-2'>
        <Button size='small' href='/sankirtana' label='Add your score' />
      </CardFooter>
    </Card>
    <ProfileGraph dashData={dashData} />
  </>)
}

export default Profile