import { Layer, Button, Box, Text } from 'grommet'
import { FormClose } from 'grommet-icons'

const ConfirmBox = ({ label, handleConfirm, handleClose }) => {

  return (
    <Layer onEsc={handleClose} onClickOutside={handleClose}>
      <Box
        background='light-2'
        tag='header'
        justify='end'
        align='center'
        direction='row'
      >
        <Button
          icon={<FormClose />}
          onClick={handleClose}
        />
      </Box>
      <Box
        fill
        background='light-2'
        align='center'
        justify='center'
        pad='large'
      >
        <Text weight='bold'>Are your sure?</Text>
        <Button type='submit' label={label} size='medium' onClick={handleConfirm} margin='large' />
      </Box>
    </Layer>
  )
}

export default ConfirmBox