import { useState } from 'react'
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell } from 'grommet'
import { SubtractCircle as DeleteIcon } from 'grommet-icons'
import { useCollectionData } from 'react-firebase-hooks/firestore'

import ConfirmBox from './ConfirmBox'

const Sankirtana = ({user, firestore }) => {
  const { uid } = user

  const sankirtanaRef = firestore.collection('sankirtana')
  const query = sankirtanaRef.where('uid', '==', uid).orderBy('createdAt', 'desc').limit(40)

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const [data, loading, error, snapshot] = useCollectionData(query)

  const takeConfirm = (snapId, keyId) => {
    setConfirmDialog(true)
    setDeleteId(snapId)
  }

  const deleteEntry = () => {
    sankirtanaRef.doc(deleteId).delete()
    setConfirmDialog(false)
  }

  return (
    <>
      {confirmDialog && <ConfirmBox label={'Yes, Delete'} handleConfirm={deleteEntry} handleClose={() => setConfirmDialog(false)} />}
      <Card background='light-1' width='large' margin={{ horizontal: 'large', vertical: 'large' }}>
        <CardHeader pad='medium' background={'light-2'}>
          <Text weight='bold'>Your sankirtana data</Text>
        </CardHeader>
        <CardBody pad='medium'>
          {(!snapshot || !snapshot.docs) && <Spinner />}
          {snapshot && snapshot.docs && (snapshot.docs.length === 0) && <Text>No data</Text>}
          {snapshot && snapshot.docs && (snapshot.docs.length > 0) && <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' border='bottom'>Time</TableCell>
                <TableCell scope='col' border='bottom'>Book</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Number</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Score</TableCell>
                <TableCell scope='col' border='bottom' align='right'>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {snapshot.docs.map((snap, index) => {
                const data = snap.data()
                return(
                  <TableRow key={index}>
                    <TableCell>{data.createdAt ? String(data.createdAt.toDate().toLocaleDateString()) : 'Just now'}</TableCell>
                    <TableCell>{data.bookName}</TableCell>
                    <TableCell align='center'>{data.numBook}</TableCell>
                    <TableCell align='center'>{data.score}</TableCell>
                    <TableCell align='right'>
                      <Button icon={<DeleteIcon color='neutral-4' />} onClick={() => {takeConfirm(snap.id, index)}} />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>}
        </CardBody>

        <CardFooter pad='medium' background='light-2'>
          <Box direction='row' gap='medium'></Box>
        </CardFooter>
      </Card>
    </>
  )
}

export default Sankirtana