import { Heading, Box, Card, CardBody, Anchor } from 'grommet'

const NotFound = ({ signInWithGoogle }) => {
  const signInCard = (
    <Card background='light-1' width='medium' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardBody pad='large'  align='center'>
        <Anchor size='medium' label='Sign in with Google' onClick={signInWithGoogle} />
      </CardBody>
    </Card>
  )
  return(
    <Box flex align='center' margin={{ horizontal: 'large', vertical: 'large' }}>
      {!signInWithGoogle && <Heading level='4' margin='none'>Error! Page not found</Heading>}
      {signInWithGoogle && signInCard}
    </Box>
  )
}

export default NotFound;