import { Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, DataTable, Meter } from 'grommet'

import { useCollectionData } from 'react-firebase-hooks/firestore'

const percentile = (arr, val) => {
  return parseInt((100 *
    arr.reduce(
      (acc, v) => acc + (v < val ? 1 : 0) + (v === val ? 0.5 : 0),
      0
    )) / arr.length);
}

const MentorBoard = ({ firestore }) => {
  const query = firestore.collection('mentors').orderBy('score', 'desc').limit(20)
  const [data, loading, error, snapshot] = useCollectionData(query)

  const scoreArray = snapshot && snapshot.docs && snapshot.docs.map((snap) => snap.data().score)

  return (
    <Card background='light-1' width='large' margin={{ horizontal: 'large', bottom: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold' size='large'>Mentors' Board</Text>
      </CardHeader>
      <CardBody pad='none'>
        {(!snapshot || !snapshot.docs) && <Spinner />}
        {snapshot && snapshot.docs && (snapshot.docs.length === 0) && <Text>No data</Text>}
        {snapshot && snapshot.docs && (snapshot.docs.length > 0) && <DataTable
          background={'light-1'}
          margin='small'
          pad='medium'
          columns={[
            {
              property: 'name',
              header: <Text>Name</Text>,
              primary: true,
            },
            {
              property: 'percent',
              header: <Text textAlign='center'>Percentile</Text>,
              align: 'center',
              primary: true,
            },
            {
              property: 'percent-bar',
              header: 'Score',
              render: datum => (
                <Box pad={{ vertical: 'xsmall' }}>
                  <Meter
                    values={[{ value: datum.percent }]}
                    thickness="small"
                    size="medium"
                  />
                </Box>
              ),
            },
          ]}
          data={snapshot.docs.map((snap, index) => {
            return {
              index,
              name: snap.id,
              percent: percentile(scoreArray, snap.data().score)
            }
          })}
        />}
      </CardBody>

      <CardFooter pad='medium' background='light-2'></CardFooter>
    </Card>
  )
}

export default MentorBoard