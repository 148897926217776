import { useState } from 'react'
import { Box, Grommet, ResponsiveContext, Spinner } from 'grommet'

import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

import { useAuthState } from 'react-firebase-hooks/auth'
import { Routes, Route, useNavigate } from 'react-router-dom'
import Nav from './components/Nav'
import Me from './components/Me'
import Sankirtana from './components/Sankirtana'
import SankirtanaForm from './components/SankirtanaForm'
import NotFound from './components/NotFound'
import MentorBoard from './components/MentorBoard'
import SankirtanaBoard from './components/SankirtanaBoard'
import Profile from './components/Profile'

firebase.initializeApp({
  apiKey: "AIzaSyA4oQcCB0dlq3De45U9ZDjCoEgFIILs3kw",
  authDomain: "sankirtan-267d8.firebaseapp.com",
  projectId: "sankirtan-267d8",
  storageBucket: "sankirtan-267d8.appspot.com",
  messagingSenderId: "1092108591355",
  appId: "1:1092108591355:web:bc9ffd287328579e76105e"
})

const auth = firebase.auth()
const firestore = firebase.firestore()

const signInWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  firebase.auth().signInWithPopup(provider)
}

function App() {
  const [user, loading] = useAuthState(auth)
  const [sidebar, setSidebar] = useState(false)
  const navigate = useNavigate()

  const theme = {
    global: {
      // https://v2.grommet.io/color
      colors: {
        brand: '#001542',
        'accent-1': '#FFB30D',
        text: '#085454',
        focus: '#7A7A7A'
      },
      font: {
        family: 'Metropolis',
        size: '18px',
        height: '20px',
      },
    },
    button: {
      size: {
        large: {
          border: {
            radius: "4px"
          }
        },
        medium: {
          border: {
            radius: "4px"
          }
        },
        small: {
          border: {
            radius: "4px"
          }
        }
      }
    }
  };

  const showSidebar = () => {
    setSidebar(!sidebar)
  }

  const signOut = () => {
    auth.currentUser && auth.signOut()
    navigate('/')
  }

  return (
    <Grommet theme={theme}>
      <ResponsiveContext.Consumer>
        {size => (
          <Box>
            <Nav showSidebar={showSidebar} user={user} userLoading={loading} signInWithGoogle={signInWithGoogle} />
            <Box direction='row' flex overflow={{ horizontal: 'hidden' }}>
              <Box flex align='center'>
                <Routes>
                  <Route path="/" element={
                    <>
                      <SankirtanaBoard user={user} firestore={firestore} />
                      <MentorBoard firestore={firestore} />
                    </>
                  } />

                  <Route path="/me" element={
                    <>
                      {loading && <Spinner />}
                      {!loading && user && <Me user={user} firestore={firestore} signOut={signOut} />}
                      {!loading && !user && <NotFound signInWithGoogle={signInWithGoogle} />}
                    </>
                  } />

                  <Route path="/sankirtana" element={
                    <>
                      {loading && <Spinner />}
                      {!loading && user && (<>
                        <Sankirtana user={user} firestore={firestore} />
                        <SankirtanaForm user={user} firestore={firestore} />
                      </>)}
                      {!loading && !user && <NotFound signInWithGoogle={signInWithGoogle} />}
                    </>
                  } />

                  <Route path="/profile/:uid" element={
                    <Profile firestore={firestore} />
                  } />

                  <Route path="*" element={
                    <NotFound />
                  } />
                </Routes>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
}

export default App;