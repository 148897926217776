import { useEffect, useState, useRef } from 'react'
import { Form, FormField, TextInput, Button, Box, Card, CardBody, CardFooter, CardHeader, Select, Text } from 'grommet'
import { useNavigate, useLocation } from 'react-router-dom'

import firebase from 'firebase/compat/app'

import Notify from './Notify'

const SankirtanaForm = ({ user, firestore }) => {
  const { uid } = user

  const [notify, setNotify] = useState(false)
  const [bookName, setBookName] = useState('')
  const [numBook, setNumBook] = useState('')
  const [mentor, setMentor] = useState('')
  const [books, setBooks] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const timerRef = useRef(null)

  useEffect(() => {
    let isCancelled = false
    firestore.collection('profiles').doc(uid).get().then(profile => {
      if (!isCancelled) {
        const profileData = profile.data()
        setMentor(profileData.mentor)
      }
    })

    firestore.collection('books').get().then(books => {
      if (!isCancelled) {
        setBooks(books.docs.map(snap => snap.data()))
      }
    })

    return () => {
      isCancelled = true
      clearTimeout(timerRef.current)
    }
  }, [])

  const saveEntry = async(e) => {
    e.preventDefault()
    let score = 0
    const bookSelected = books.find(book => book.name === bookName)
    score = bookSelected && (bookSelected.score * numBook)

    const sankirtanaRef = firestore.collection('sankirtana')
    try {
      await sankirtanaRef.add({
        uid,
        mentor,
        score,
        bookName,
        numBook,
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      })
      setNotify({ status: 'normal', title:'Hare Krishna🙏', message: 'Sankirtana data was added' })
      setBookName('')
      setNumBook('')
      setMentor('')
      timerRef.current = setTimeout(() => {
        if (location.pathname !== '/sankirtana') {
          navigate('/sankirtana')
        }
      }, 2000)
    } catch (error) {
      setNotify({ status: 'critical', title:'Something went wrong', message: 'Contact aggd.hkm@gmail.com' })
    }
  }

  return (
    <Card background='light-1' width='large' margin={{ horizontal: 'large', bottom: 'large' }}>

      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}

      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold'>Make a new entry</Text>
      </CardHeader>
      <Form onSubmit={saveEntry} validate='submit' messages={{required: 'Required', invalid: 'Invalid'}} >
        <CardBody pad='medium' size='large'>
          <FormField name='bookName' htmlFor='bookName' label='Book name' required>
            <Select
              id='bookName' name='bookName' required
              options={books.map(x => x.name)}
              value={bookName}
              onChange={({ option }) => setBookName(option)}
            />
          </FormField>

          <FormField name='numBook' htmlFor='numBook' label='No. of books distributed today' required margin={{top: 'medium'}} validate={(fieldData) => {
            if (!/^[1-9]\d*$/.test(fieldData)) {
              return {message:'Invalid number', status: 'error'}
            }
          }}>
            <TextInput id='numBook' name='numBook' value={numBook} onChange={(e) => setNumBook(e.target.value)} />
          </FormField>
        </CardBody>

        <CardFooter pad='medium' background='light-2'>
          <Box direction='row' gap='medium'>
            <Button type='submit' size='medium' label='Add entry' />
          </Box>
        </CardFooter>
      </Form>
    </Card>
  )
}

export default SankirtanaForm