import { DataChart, Card, CardHeader, CardBody, CardFooter, Text, Box } from 'grommet'

const ProfileGraph = ({ dashData }) => {

  if (!dashData) {
    return (<></>)
  }

  let data = dashData.map(d => {
    return {
      date: d.createdAt.toDate().toLocaleDateString(),
      score: d.score
    }
  }).reduce((n,o) => {
    if (n[o['date']]) {
      n[o['date']] += o['score']
    } else {
      n[o['date']] = o['score']
    }
    return n
  }, {})

  return(
    <Card background='light-1' width='large' margin={{ horizontal: 'large', bottom: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold'>Profile Graph</Text>
      </CardHeader>
      <CardBody pad='medium' size='large' overflow={{ horizontal: 'auto' }}>
        <DataChart data={Object.keys(data).map(key => {
            return { 'date': key,  'score': data[key] }
          })}
          series={[{
            label: "Date",
            property: "date",
          },{
            label: "Score",
            property: "score",
          }]}
          guide={{ x: { granularity: 'fine' }, y : { granularity: 'fine' } }} 
          detail={true}
          gap={'small'}
          size={{ width: 'fill', height: '400px' }}
          axis={{ x: { granularity: 'fine', property: 'date' }, y : { granularity: 'medium', property: 'score' } }}
        />
      </CardBody>
      <CardFooter pad='medium' background='light-2'>
        <Box direction='row' gap='medium'></Box>
      </CardFooter>
    </Card>
  )
}

export default ProfileGraph