import { useEffect, useState } from 'react'
import { Avatar, Form, FormField, TextInput, Button, Box, Card, CardBody, CardFooter, CardHeader, Anchor, Select, Text } from 'grommet'

import firebase from 'firebase/compat/app'

import Notify from './Notify'

const Me = ({user, firestore, signOut }) => {
  const profileRef = user && user.uid && firestore.collection('profiles').doc(user.uid)

  const [profileName, setProfileName] = useState(user && user.displayName)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [mentor, setMentor] = useState('')
  const [notify, setNotify] = useState(false)
  const [mentors, setMentors] = useState([])
  const [email, setEmail] = useState(user && user.email)
  const [userPhoto, setUserPhoto] = useState(user && user.photoURL)

  useEffect(() => {
    let isCancelled = false

    profileRef && profileRef.get().then((profile) => {
      if (!isCancelled) {
        const profileData = profile.data()
        setProfileName(profileData.profileName)
        setPhoneNumber(profileData.phoneNumber)
        setMentor(profileData.mentor)
        profileData.email && setEmail(profileData.email)
        profileData.photoURL && setUserPhoto(profileData.photoURL)
      }
    })

    firestore.collection('mentors').get().then(mentors => {
      if (!isCancelled) {
        setMentors(mentors.docs.map(snap => snap.id))
      }
    })

    return () => {
      isCancelled = true
    }
  }, [user])

  const saveProfile = async(e) => {
    e.preventDefault()

    try {
      await profileRef.set({
        profileName,
        phoneNumber,
        userPhoto,
        mentor,
        email,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true })
      setNotify({ status: 'normal', title:'Hare Krishna🙏', message: 'Profile was updated' })
    } catch (error) {
      setNotify({ status: 'critical', title:'Something went wrong', message: 'Contact aggd.hkm@gmail.com' })
    }
  }

  return (
    <Card background="light-1" width={'large'} margin={{ horizontal: 'large', vertical: 'large' }}>

      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}

      <CardHeader pad='medium' background={"light-2"}>
        <Avatar src={userPhoto} />
        <Text weight={'bold'}>{profileName}</Text>
      </CardHeader>
      <CardBody pad="medium" size='large' style={{overflowY: 'auto'}}>
        <Form onSubmit={saveProfile} validate='blur' messages={{required: "Required"}} >
          <FormField name='name' htmlFor='name' label='Name' required>
            <TextInput pad='none' id='name' name='name' value={profileName} onChange={(e) => setProfileName(e.target.value)} />
          </FormField>

          <FormField name='phone' htmlFor='phone' label='Phone' required margin={{top: 'medium'}}>
            <TextInput id='phone' name='phone' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </FormField>

          <FormField name='mentor' htmlFor='mentor' label='Mentor' margin={{top: 'medium'}}>
            <Select
              id='mentor' name='mentor' required
              options={mentors}
              value={mentor}
              onChange={({ option }) => setMentor(option)}
            />
          </FormField>

          <FormField name='email' htmlFor='email' label='Email' disabled margin={{top: 'medium', bottom: 'medium'}}>
            <TextInput id='email' name='email' value={email} readOnly />
          </FormField>

          <Box direction="row" gap="medium">
            <Button type="submit" size='medium' label="Update profile" />
          </Box>
        </Form>
      </CardBody>
      <CardFooter pad='medium' background="light-2" style={{ zIndex: '1' }}>
        <Anchor href='/sankirtana' label='View your Sankirtana score' />
        <Button label='Sign out' size='small' onClick={signOut} />
      </CardFooter>
    </Card>
  )
}

export default Me